import React, { useState, useEffect, useRef, createRef } from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import { color, font, ease, device } from "../../layout/global"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const CarouselComponent = ({ id, className, carouselContent, string }) => {
  // Transição entre slides
  const transitionTime = 750
  // Slide ativo
  const [ currentSlide, setCurrentSlide ] = useState(0)
  // Saber quando o rato está por cima do Prev ou do Next
  const [ hasHovered, setHasHovered ] = useState(0)
  // Enquanto a animação estiver a decorrer, prevenir a mudança de slide
  const [ isChanging, setIsChanging ] = useState(false)

  // References to the DOM
  const triggerRef = useRef(null)
  const slideRefs = useRef(carouselContent.map(() => createRef()))
  const indicatorsRefs = useRef(carouselContent.map(() => createRef()))
  
  useEffect(() => {
    if (typeof window !== 'undefined' && window.matchMedia(device.tabletP).matches) {
      let tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          anticipatePin: 1,
          trigger: triggerRef.current,
          toggleActions: 'play none none none',
          id: 'carousel',
          start: 'center center',
          end: '+=100% bottom'
        }
      })
      slideRefs.current.map(i => 
        tl.fromTo(i.current.parentElement, {opacity: 0}, {opacity: 1, duration: 1}, 0)
      )
      indicatorsRefs.current.map(i => 
        tl.fromTo(i.current.parentElement, {opacity: 0}, {opacity: 1, duration: 2}, 0)
      )
      tl.set(triggerRef.current, { backgroundColor: 'white'})
      tl.to({}, { duration: 1 }, '>')
    }
  }, [])
  
  useEffect(() => {
    setTimeout(() => {
      // Ao mudar de slide, o indicador correspondente recebe a class 'selected'
      let dots = document.querySelectorAll('.dot')
      dots.forEach(dot => dot.value === currentSlide ? dot.classList.add('selected') : dot.classList.remove('selected'))

      // Ao mudar de slide, definimos qual os pickers que correspondem à década anterior, atual, e posterior
      let currentSlider = document.querySelector('.slide.selected'),
          previousSlider = currentSlider.previousElementSibling,
          nextSlider = currentSlider.nextElementSibling,

          currentPicker = currentSlider.firstElementChild.firstElementChild,
          previousPicker = (previousSlider ? previousSlider.firstElementChild.firstElementChild : null),
          nextPicker = (nextSlider ? nextSlider.firstElementChild.firstElementChild : null),

          currentPickerText = currentPicker.querySelector('.slide-picker-decade'),
          previousPickerText = (previousPicker ? previousPicker.querySelector('.slide-picker-decade') : null),
          nextPickerText = (nextPicker ? nextPicker.querySelector('.slide-picker-decade') : null)
      
      // Define-se as alterações de CSS. Transições estão definidas no styled-component
      if(currentPicker) {
        currentPicker.style.left = '50%'
        currentPicker.style.opacity = 1
        currentPickerText.style.opacity = 1
      }    
      if(previousPicker) {
        previousPicker.style.left = '102.5%'
        previousPicker.style.opacity = 0.3
        previousPickerText.style.opacity = 0
      }
      if(nextPicker) {
        nextPicker.style.left = '-2.5%'
        nextPicker.style.opacity = 0.3
        nextPickerText.style.opacity = 0
      }
      // Define-se as alterações de CSS ao fazer hover nas áreas dos cantos que funcionam como botões para retroceder e avançar
      if(hasHovered === 1 && nextPicker) {
        nextPicker.style.opacity = 1
        nextPickerText.style.opacity = 1

      } else if(hasHovered === -1 && previousPicker) {
        previousPicker.style.opacity = 1
        previousPickerText.style.opacity = 1
      } else {
        if(previousPicker) {
          previousPicker.style.opacity = 0.3
          previousPickerText.style.opacity = 0
        }
        if(nextPicker) {
          nextPicker.style.opacity = 0.3
          nextPickerText.style.opacity = 0
        }
      }
    }, 50)
  },[currentSlide, hasHovered])
  
  // Ao clicar num indicador, o slider muda para o slide correspondente
  const changeSlide = e => {
    if(isChanging === false) {
      let dot = e.target
      setCurrentSlide(dot.value)
      setIsChanging(true)
      setTimeout(function() {
        setIsChanging(false)
      }, transitionTime)
    }

  }
  // Andar para trás
  const Prev = () => {
    if(isChanging === false) {
      currentSlide !== 0 && setCurrentSlide(currentSlide - 1)
      setIsChanging(true)
      setTimeout(function() {
        setIsChanging(false)
      }, transitionTime)
    }
  }
  // Andar para a frente
  const Next = () => {
    if(isChanging === false) {
      currentSlide !== (carouselContent.length - 1) && setCurrentSlide(currentSlide + 1)
      setIsChanging(true)
      setTimeout(function() {
        setIsChanging(false)
      }, transitionTime)
    }
  }
  // Chamada sempre que há alteração para o slider saber qual o slide ativo
  const updateCurrentSlide = (index) => {
    currentSlide !== index && setCurrentSlide(index)
  }

  return (
        <CarouselContainer className="carousel-container" id={id} ref={triggerRef}>
          {/* eslint-disable-next-line */}
          <a id="history" className="hidden-anchor"></a>
          <button onClick={Prev} onMouseEnter={() => setHasHovered(-1)} onMouseLeave={() => setHasHovered(0)} id="carousel-prev" className="control-arrows" aria-label="Previous Item"/>
          <button onClick={Next} onMouseEnter={() => setHasHovered(1)} onMouseLeave={() => setHasHovered(0)} id="carousel-next" className="control-arrows" aria-label="Next Item"/>
          <ul className="control-dots">
            {carouselContent.map((x, index) => 
              <li key={index} className="dot" value={index} role="presentation" onClick={e => changeSlide(e)} aria-label={`slide item${index}`} ref={indicatorsRefs.current[index]}>
              </li>
            )}
          </ul>
          <Wrapper
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            transitionTime={transitionTime}
            className={className}
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            swipeable={true}
            hasHovered={hasHovered}
          >
            {carouselContent.map((i, index) => 
              <div className="slide-container" key={index} id={`slide-${index}`} ref={slideRefs.current[index]}>
                <div className="slide-picker">
                  <span className="slide-picker-marker"></span>
                  <p className="slide-picker-decade">{string}</p>
                  <p className="slide-picker-number">{i.year}</p>
                </div>
                <div className="slide-content">
                  <div className="slide-content-text">
                    {i.text.map((j, index) =>
                      <div className="single-year-line" key={index}>
                        <p className="single-year-line-year">{j.year}</p>
                        <p className="single-year-line-text">{j.text}</p>
                      </div>
                    )}
                  </div>
                  <div className="slide-content-image">
                    {i.image &&
                        <img 
                            src={i.image} 
                            alt='carousel' 
                            style={{
                                objectFit:'contain', 
                                objectPosition:'center center'
                            }}
                        />
                    }
                  </div>
                </div>
              </div>
            )}
          </Wrapper>
        </CarouselContainer>
  )
}

export default CarouselComponent

const Wrapper = styled(Carousel)`
  background: ${color.greyAbout};

  @media ${device.mobileP}{
    min-height:100vh;
  }

  @media ${device.tabletP}{
    min-height:initial;
  }
  
  .slide {
    position: relative;
    width: 100vw;
    background-color: ${color.greyAbout};

    @media ${device.tabletP}{
      height: 100vh;
    }
  }

  .slide-container {
    bottom: 0;
    width: 100%;
    background-color: ${color.white};
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobileP}{
      padding: 90px 0 0 0;
    }

    @media ${device.tabletP}{
      position: absolute;
      height: 85%;
      padding: 0;
    }
  }

  .slide-picker {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    transition: ${props => `left ${props.transitionTime + 150}ms ease-in-out, opacity ${props.transitionTime - 500}ms ease-in-out`};

    @media ${device.mobileP}{
      height: 120px;
    }

    @media ${device.tabletP}{
      height: 28%;
    }
  }

  .slide-picker-marker {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    height: 25px;
    width: 3px;
    background-color: ${color.red};
  }

  .slide-picker-decade {
    ${font.robotoBold};
    color: ${color.grey};
    text-transform: uppercase;
    transition: ${props => `all ${props.transitionTime}ms ease-in-out`};

    @media ${device.mobileP}{
      font-size:1.4rem;
    }

    @media ${device.tabletP}{
      font-size: 2.2rem;
    }
  }

  .slide-picker-number {
    ${font.financierLight};
    color: ${color.red};
    line-height: 0.75;

    @media ${device.mobileP}{
      font-size:8rem;
    }

    @media ${device.tabletP}{
      font-size: 20rem;
    }
  }

  .slide-content {
    background-color: ${color.greyAbout};

    @media ${device.mobileP}{
      display: flex;
      flex-direction: column-reverse;
    }

    @media ${device.tabletP}{
      width: 70%;
      height: 60%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    } 
  }

  .slide-content-text {
    display: flex;
    flex-direction: column;
    ${font.robotoMedium};
    font-size: 1.8rem;

    @media ${device.mobileP}{
      padding: 30px 20px;
    }

    @media ${device.tabletP}{
      padding:0;
      margin: 10%;
    }
  }

  .single-year-line {
    display: grid;
    grid-template-columns: minmax(4.5rem, auto) 1fr;
    grid-column-gap: 10px;
    margin-bottom: 1.5rem;

    @media ${device.mobileP}{
      font-size:1.5rem;
    }

    @media ${device.tabletP}{
      font-size:initial;
    }
  }

  .single-year-line-year {
    color: ${color.grey};
    line-height: 18px;
  }

  .single-year-line-text {
    text-align: left;
    color: ${color.greyDark};
    line-height: 18px;
  }

  .slide-content-image {
    background-color: ${color.greyDark};
    overflow: hidden;
  }

  .slide-content-image > div {
    height: 100%;
  }

  .slide-content-image > div img {
    object-fit: cover !important;
  }

  .gatsby-image-wrapper > div{
    padding-bottom:50% !important;
  }


  
  .carousel .slide {
    transition: all 0.15s ${ease.out};
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .container,
  .image {
    height: 100%;
  }
  .container {
    position: relative;
  }
  .description {
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translateY(-40%);
    opacity: 0;
    transition: all 0.5s ${ease.out};
    transition-delay: 0.75s;
  }
  .title {
    width: 550px;
    color: ${color.white};
    z-index: 1;
    ${font.robotoBold};
    font-size: 2.2rem;
    text-align: left;
  }
  .text {
    margin-top: 20px;
    width: 525px;
    color: ${color.white};
    z-index: 1;
    ${font.robotoRegular};
    font-size: 1.8rem;
    text-align: left;
  }
  span{
    color: ${color.red};
  }
  .image {
    opacity: 0;
    transition: all 0.5s ${ease.out};
    transition-delay: 0.5s;
  }
  .carousel .slide.selected .description {
    transform: translateY(-60%);
    opacity: 1;
  }
  .carousel .slide.selected .image {
    opacity: 1;
  }
 
`

export const CarouselContainer = styled.div`
  position: relative;
  color: white;

  .hidden-anchor {
    position: absolute;
    visibility: hidden;
    width: 0px;
    transform: translateY(1px);
  }

  #carousel-prev {
    left: 0;
  }
  #carousel-next {
    right: 0;
  }

  .control-dots {
    position: absolute;
    margin: 10px 0;
    text-align: center;
    width: 100%;
    z-index: 1;
    bottom: 5%;
    display: flex;
    justify-content: center;

    @media ${device.mobileP}{
      padding:0 20px;
      bottom:15px;
    }

    @media ${device.tabletP}{
      padding:0;
      bottom:5%;
    }
  }
  
  .control-dots .dot {
    background: ${color.grey};
    transition: all 0.3s ${ease.out};
    box-shadow: none;
    border-radius: 0%;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
    position: relative;

    @media ${device.mobileP}{
      width: 30px;
      height:2px;
    }

    @media ${device.tabletP}{
      width: 50px;
      height:3px;
    }
    
    &:hover {
      background: ${color.greyDark};
    }

    &:focus {
      outline: 0;
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      background-color: transparent;
      width: 100%;
      height: 1200%;
      top: 0;
      transform: translateY(-50%);
    }
  }

  .control-dots .dot.selected {
    background: ${color.red};

    &:hover { background: ${color.red}; }
  }

  .control-arrows {
    position: absolute;
    height:100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    width: 8%;
    fill: ${color.white};
    transition: all 0.3s ${ease.out};
    padding: 5px;
    background: none;
    outline: 0;
    border: none;

    &:hover {
      fill: ${color.red};
      cursor: pointer;
    }
  }
`