import React from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Video from "../components/Video"
import VideoDetailMobile from "../components/VideoDetail/mobile"
import Hero from "../components/Hero"
import CarouselAbout from "../components/CarouselAbout"
import FormContact from "../components/FormContact"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

// ASSETS
import Pub from "../assets/videos/Homepage/institucional.mp4"
import PubPreview from "../assets/videos/Homepage/preview.mp4"
import PubMobile from "../assets/videos/Homepage/Mobile/preview_mobile.mp4"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

const About = ({ location }) => {
  const {t} = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout 
        location={location}
        footer={t('footer', { returnObjects: true })}
        header={t('header', { returnObjects: true })}
    >
      <SEO
        pathname={location.pathname}
        title={t('seo', { returnObjects: true })['title']}
        img={t('seo', { returnObjects: true })['img']}
        imageHeight="628"
        imageWidth="1200"
        lang="pt"
        domain={location?.hostname}
      />
      {!breakpoints.tl ? (
        <Video
          className="full-width full-height scrollable"
          srcp={Pub}
          src={PubPreview}
          poster="https://ramalhos.com/video_posters/Homepage/institucional.jpg"
          width="100%"
          id="video-homepage"
          fullString={t('videoHome', { returnObjects: true })['info']}
          toClick="#about-hero-banner"
          noPreview={true}
        />
      ) : (
        <VideoDetailMobile
          src={PubMobile}
          poster="https://ramalhos.com/video_posters/Homepage/Mobile/preview_mobile.jpg"
          id="video-detail-home-mobile"
          link
          srcOriginal={Pub}
          text={t('videoHome', { returnObjects: true })['info']}
        />
      )}
      <Hero 
        text={t('introduction', { returnObjects: true })['text']} 
        image={t('introduction', { returnObjects: true })['image']} 
      />
      <CarouselAbout
        carouselContent={t('carousel', { returnObjects: true })['sliders']}
        string={t('carousel', { returnObjects: true })['decade']}
      />
      {!(activeLanguage === 'br') &&
            <FormContact
                id="form-contact"
                info={t('form', { returnObjects: true })}
                lang={activeLanguage}
                location={location}
            />
        }
    </Layout>
  )
}

export default About

export const QueryAboutPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["about", "home", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`
